<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar>
              <v-tabs show-arrows v-model="tab">
                <v-tab key="contact"><v-icon left> mdi-account </v-icon></v-tab>
                <!-- <v-tab key="shipping">
                  <v-icon left> mdi-truck-fast-outline </v-icon>
                </v-tab> -->
                <v-tab key="photo">
                  <v-icon> mdi-camera-outline </v-icon>
                </v-tab>
                <v-tab key="menus">
                  <v-icon> mdi-camera-plus-outline </v-icon>
                </v-tab>
                <v-tab key="map">
                  <v-icon> mdi-map-search-outline </v-icon>
                </v-tab>
                <v-tab key="tax">
                  <v-icon left> mdi-bank-outline </v-icon>
                </v-tab>
                <v-tab
                  key="settings"
                  v-if="this.$store.state.profile.user_access === 'Owner'"
                >
                  <v-icon left> mdi-cog-outline </v-icon>
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-btn x-small color="info" fab @click="close()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-app-bar>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-tabs-items v-model="tab">
              <v-tab-item key="contact">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="item.group_name"
                      id="category"
                      hide-details="auto"
                      label="Restaurante"
                      :items="groups"
                      :rules="f_required"
                      outlined
                      required
                      :disabled="!edit"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="item.subgroup_name"
                      id="subcategory"
                      hide-details="auto"
                      label="Comida"
                      :items="subgroups"
                      outlined
                      required
                      :disabled="!edit"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="item.name"
                      id="name"
                      :rules="f_required"
                      label="Nombre"
                      hide-details="auto"
                      required
                      dense
                      outlined
                      :disabled="!edit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="item.address"
                      id="address"
                      :rules="f_required"
                      label="Dirección"
                      hide-details="auto"
                      required
                      dense
                      outlined
                      :disabled="!edit"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="4">
                    <v-combobox
                      v-model="item.country"
                      id="category"
                      hide-details="auto"
                      label="País"
                      :items="countries"
                      :rules="f_required"
                      outlined
                      required
                      dense
                      :disabled="!edit"
                      @change="country_change"
                    />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-combobox
                      v-model="item.province"
                      id="category"
                      hide-details="auto"
                      label="Provincia"
                      :items="state"
                      :rules="f_required"
                      outlined
                      required
                      dense
                      :disabled="!edit"
                      @change="province_change"
                    />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-combobox
                      v-model="item.city"
                      id="category"
                      hide-details="auto"
                      label="Ciudad"
                      :items="city"
                      :rules="f_required"
                      outlined
                      required
                      dense
                      :disabled="!edit"
                    />
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field
                      v-model="item.mobile"
                      label="Celular"
                      hide-details="auto"
                      dense
                      outlined
                      append-icon="mdi-phone-outline"
                      @click:append="call_contact(item.mobile)"
                      v-mask="'(###) ###-####'"
                      :readonly="!edit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.email"
                      label="Correo Electrónico"
                      hide-details="auto"
                      dense
                      outlined
                      :disabled="!edit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field
                      v-model="item.facebook"
                      label="Facebook"
                      hide-details="auto"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field
                      v-model="item.instagram"
                      label="Instagram"
                      hide-details="auto"
                      dense
                      :disabled="!edit"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="item.qr"
                      prepend-inner-icon="mdi-qrcode-scan"
                      append-icon="mdi-magnify"
                      @click:prepend-inner="openQR()"
                      @click:append="openURL()"
                      label="QR"
                      hide-details="auto"
                      dense
                      :disabled="!edit"
                      outlined
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      v-model="item.note"
                      solo
                      label="Observaciones"
                      :disabled="!edit"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    v-if="this.$store.state.profile.user_access === 'Owner'"
                  >
                    <v-select
                      v-model="item.status"
                      :items="storeStatus"
                      label="Estado"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    v-if="this.$store.state.profile.user_access === 'Sales'"
                  >
                    <v-select
                      v-model="item.sales_status"
                      :items="saleStatus"
                      label="Estado"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-tab-item>
              <!-- <v-tab-item key="shipping">
                <v-card tile>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="item.shipping_name"
                          id="shipping_name"
                          label="Nombre"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="item.shipping_address"
                          id="shipping_address"
                          label="Dirección"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.shipping_address2"
                          id="shipping_address2"
                          label="Local/Oficina/Unidad"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.shipping_city"
                          id="shipping_city"
                          label="Ciudad"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.shipping_province"
                          id="shipping_province"
                          label="Provincia"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.shipping_mobile"
                          label="Celular"
                          hide-details="auto"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="item.shipping_email"
                          label="Correo Electrónico"
                          hide-details="auto"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item> -->
              <v-tab-item key="photo" align="center" justify="center">
                <v-img
                  cover
                  aspect-ratio="1"
                  class="grey lighten-2"
                  :src="productPhoto"
                  max-height="400"
                >
                  <v-fab-transition>
                    <v-btn
                      class="mx-2 btn-upload"
                      fab
                      light
                      small
                      color="secondary"
                      @click="chooseImage"
                    >
                      <v-icon dark> mdi-camera-plus-outline </v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-img>
                <input
                  class="file-input"
                  ref="fileInput"
                  type="file"
                  @input="onSelectFile"
                />
              </v-tab-item>
              <v-tab-item key="menus" align="center" justify="center">
                <v-img
                  cover
                  aspect-ratio="1"
                  class="grey lighten-2"
                  :src="menuPhoto"
                  max-height="350"
                >
                  <v-fab-transition>
                    <v-btn
                      class="mx-2 btn-upload"
                      fab
                      light
                      small
                      color="secondary"
                      @click="chooseMenu"
                    >
                      <v-icon dark> mdi-camera-plus-outline </v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-img>
                <input
                  class="file-input"
                  ref="menuInput"
                  type="file"
                  @input="onSelectMenu"
                />
              </v-tab-item>
              <v-tab-item key="map">
                <div class="map">
                  <StoreMap
                    @store-location="updateLocation"
                    :storeLocation="item.location"
                    :recenter="recenter"
                  />
                </div>
              </v-tab-item>
              <v-tab-item key="tax">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="item.type_id"
                      :items="type_id"
                      label="Tipo de documento"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.identification_id"
                      label="Numero de documento"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-select
                      v-model="item.additional_accoun_id"
                      :items="typeaccountsID"
                      label="Régimen fiscal"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-select
                      v-model="item.tax_level_code"
                      :items="TaxLevelCodes"
                      label="Regimen fiscal"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item
                key="settings"
                v-if="this.$store.state.profile.user_access === 'Owner'"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <v-switch
                      v-model="webSet.online"
                      label="Recibe ordenes el línea"
                      color="indigo"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-switch
                      v-model="webSet.payments"
                      label="Recibe pagos en línea"
                      color="indigo"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-switch
                      v-model="webSet.whitPhoto"
                      label="Productos con foto"
                      color="indigo"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-switch
                      v-model="webSet.categories"
                      label="Mostrar categorias"
                      color="indigo"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      v-model="webSet.categories_order"
                      :items="categoriesLst"
                      label="Orden de Categories"
                      outlined
                      dense
                      multiple
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="webSet.font"
                      :items="fontsLst"
                      label="Tipo de documento"
                      outlined
                      dense
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="webSet.epaycoPK"
                      label="Epayco Public Key"
                      hide-details="auto"
                      dense
                      outlined
                      :disabled="!edit"
                    />
                  </v-col>
                  <v-col cols="4" md="4">
                    <v-switch
                      v-model="webSet.epayco_test"
                      label="Prueba"
                      color="indigo"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-color-picker
                      v-model="webSet.bgcolor"
                      dot-size="25"
                      hide-canvas
                      hide-inputs
                      mode="hexa"
                      show-swatches
                      swatches-max-height="100"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-bottom-navigation grow>
              <v-btn
                @click="delete_itm(item)"
                :disabled="newItem"
                v-if="this.$store.state.profile.user_access === 'Owner'"
              >
                <span>Borrar</span>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              <v-btn @click="edit = !edit">
                <span>Editar</span>
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn :disabled="!valid" type="submit">
                <span>Guardar</span>
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-model="QRdialog"
      persistent
      max-width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      light
    >
      <v-card tile height="100%">
        <v-card-title class="pa-0">
          <v-app-bar>
            <v-spacer></v-spacer>
            <v-btn x-small color="danger" fab @click="QRdialog = !QRdialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-app-bar>
        </v-card-title>
        <v-card-text class="pa-0">
          <StreamBarcodeReader
            @decode="onDecode"
            @loaded="onLoaded"
            :stopCamera="stopCamera"
          />
        </v-card-text>
        <v-card-text class="pa-6">
          <v-row class="fill-height" align="center" justify="center">
            {{ this.result }}
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="red"
            type="button"
            @click="dialog_confirm = !dialog_confirm"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createStore.js";
import createWebset from "../utils/createSetting.js";
import cities_lst from "../utils/cities.js";
import StoreMap from "./Location.vue";
import { StreamBarcodeReader } from "../utils/barcodereader.js";
export default {
  name: "StoreParty",
  components: { StoreMap, StreamBarcodeReader },
  data() {
    return {
      storeStatus: ["QR4", "QR", "Active", "Inactive", "Hold"],
      saleStatus: [
        "Pendiente",
        "En fabrica",
        "Listo para visita",
        "Interesado",
        "No interesado",
      ],
      edit: false,
      menuPhoto: null,
      QRdialog: false,
      stopCamera: false,
      initCamera: false,
      result: "",
      productPhoto: null,
      viewMap: false,
      recenter: false,
      ipgeo: null,
      cities: cities_lst(),
      countries: [],
      state: [],
      city: [],
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      compressed_menu: null,
      reg_delete: null,
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      type_id: [
        { value: "13", text: "Cédula de ciudadanía" },
        { value: "31", text: "NIT" },
        { value: "11", text: "Registro civil" },
        { value: "12", text: "Tarjeta de identidad" },
        { value: "21", text: "Tarjeta de extranjería" },
        { value: "22", text: "Cédula de extranjería" },
        { value: "41", text: "Pasaporte" },
        { value: "42", text: "Documento de identificación extranjero" },
        { value: "50", text: "NIT de otro país" },
      ],
      typeaccountsID: [
        { value: "1", text: "Persona Juridica" },
        { value: "2", text: "Persona Natural" },
      ],
      TaxLevelCodes: [
        { value: "04", text: "Simple" },
        { value: "05", text: "Ordinario" },
      ],
      stloc: null,
      fontsLst: [
        "Amatic",
        "Architects",
        "Carattere",
        "Montserrat",
        "Roboto",
        "Shadows",
      ],
      webSet: createWebset(),
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: createItem(),
    },
    groups: {
      type: Array,
      required: false,
    },
    subgroups: {
      type: Array,
      required: false,
    },
    categoriesLst: {
      type: Array,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    var dta = this.cities;
    const keys = Object.keys(dta).sort();
    this.countries = keys;
    this.stloc = this.item.location;
    // if (this.item.setting !== null) {
    //   this.webSet = JSON.parse(this.item.setting);
    // } else {
    //   this.webSet = createWebset();
    // }
    //console.log("mounted", this.item);
    //this.webSet = this.item.setting;
  },
  methods: {
    fontChange(e) {
      this.webSet.font = e;
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getImagePhoto: function (account, code, src) {
      var url =
        "https://smartchef.pro/photos/" + account + "/" + code + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    getImageMenu: function (account, code, src) {
      var url =
        "https://smartchef.pro/menus/" + account + "/" + code + "/" + src;
      if (this.compressed_menu) url = this.compressed_menu;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    chooseMenu() {
      this.$refs.menuInput.click();
    },
    openURL() {
      window.open(this.item.qr, "", "width=400,height=800");
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/jpeg");
            this.compressed_image = imageUrl;
            this.productPhoto = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    onSelectMenu() {
      const input = this.$refs.menuInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/jpeg");
            this.compressed_menu = imageUrl;
            this.menuPhoto = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.menus = files[0].name.toLowerCase();
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        this.getLocation().then((e) => {
          if (this.newItem) {
            this.item.geodata = JSON.stringify(e);
            this.item.agent = this.$store.state.profile.name;
          }
          this.$emit("itmUpdated", this.item);
          this.item.setting = JSON.stringify(this.webSet);
          var qry = {
            account: this.$store.state.profile.account,
            table: this.table,
            data: [this.item],
          };
          if (this.compressed_image) {
            qry.photo = this.compressed_image;
          }
          if (this.compressed_menu) {
            qry.menu = this.compressed_menu;
          }
          webserver("put_table", qry, () => {
            this.compressed_image = null;
            this.compressed_menu = null;
            this.loading_status = false;
            this.$emit("refresh", true);
          });
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      webserver("delete_record", qry, () => {
        this.reg_delete = null;
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", true);
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    country_change(e) {
      this.state = [];
      this.city = [];
      this.item.province = null;
      this.item.city = null;
      var stt = this.cities[e];
      this.state = Object.keys(stt).sort();
    },
    province_change(e) {
      this.city = [];
      this.item.city = null;
      this.city = this.cities[this.item.country][e].sort();
    },
    updateLocation(e) {
      this.item.location = JSON.stringify(e);
    },
    onDecode(e) {
      this.stopCamera = true;
      this.QRdialog = false;
      this.item.qr = e;
    },
    openQR() {
      this.stopCamera = false;
      this.QRdialog = true;
    },
    onLoaded(e) {
      console.log(e);
    },
    getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (res) => {
              resolve([res.coords.latitude, res.coords.longitude]);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
        } else {
          reject("No Geolocation support.");
        }
      });
    },
  },
  watch: {
    item: function (e) {
      if (e.setting !== null) {
        this.webSet = JSON.parse(e.setting);
      } else {
        this.webSet = createWebset();
      }

      this.productPhoto = this.getImagePhoto(e.account, e.code, e.picture);
      this.menuPhoto = this.getImageMenu(e.account, e.code, e.menus);

      if (this.newItem) {
        this.edit = true;
        this.item.country = window.company.country;
        this.item.province = window.company.province;
        this.item.city = window.company.city;
      }
    },
  },
};
</script>

<style>
.Montserrat {
  font-family: "Montserrat", sans-serif !important;
}
.Roboto {
  font-family: "Roboto Condensed", sans-serif !important;
}

.Architects {
  font-family: "Architects Daughter", cursive !important;
}

.Shadows {
  font-family: "Shadows Into Light", cursive !important;
}

.Amatic {
  font-family: "Amatic SC", cursive !important;
}

.Carattere {
  font-family: "Carattere", cursive !important;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.file-input {
  display: none;
}
.map {
  height: 400px;
}
.btn-upload {
  top: 150px;
}
</style>